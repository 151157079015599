// LoadingSpinner.js

import "./LoadingSpinner.css";

import React from "react";

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <div className="spinner"></div>
  </div>
);

export default LoadingSpinner;
