// StripeCardInput.js

import { CardElement } from "@stripe/react-stripe-js";
import React from "react";

const StripeCardInput = () => (
  <div className="card-element">
    <CardElement />
  </div>
);

export default StripeCardInput;
